// src/theme.js
import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react';

const theme = extendTheme({
  fonts: {
    body: 'Montserrat, system-ui, sans-serif',
    heading: 'Montserrat, system-ui, sans-serif',
  },
  colors: {
    brand: {
      primary: '#de182f',
      secondary: '#000000',
    },
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: 'normal',
        borderRadius: 'sm', // Adjust the border radius
      },
      sizes: {
        md: {
          height: '48px',
          fontSize: 'lg',
        },
      },
      variants: {
        solid: {
          bg: 'brand.primary',
          color: 'white',
          _hover: {
            bg: '#bf1629',
          },
        },
      },
    },
    Link: {
      baseStyle: {
        _hover: {
          textDecoration: 'underline',
        },
      },
    },
    Input: {
      baseStyle: {
        field: {
          borderRadius: 'md', // Adjust the border radius
        },
      },
    },
    Textarea: {
      baseStyle: {
        borderRadius: 'md', // Adjust the border radius
      },
    },
    Select: {
      baseStyle: {
        field: {
          borderRadius: 'md', // Adjust the border radius
        },
      },
    },
    DrawerContent: {
      baseStyle: {
        borderRadius: 'md', // Adjust the border radius
      },
    },
  },
  styles: {
    global: {
      body: {
        bg: 'white',
        color: 'black',
      },
    },
  },
  radii: {
    none: '0',
    sm: '2px',
    md: '4px',
    lg: '8px',
    xl: '12px',
    '2xl': '16px',
    '3xl': '24px',
    full: '9999px',
  },
});

export default theme;