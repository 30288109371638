import React from 'react';
import { Box, Heading, Text, Button, Image, Flex} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import ProductGallery from '../components/ProductGallery';

const Home = () => {
  return (
    <Box>
      <Box position="relative" textAlign="center" py={20} color="black" minH="100vh">
        <Box
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          bgImage="url('/home.jpg')"
          bgSize="cover"
          bgPos="center"
          opacity={0.2}
          zIndex={-1}
        />
          <Box>
            <Heading as="h1" size="2xl" mb={4}>
              Welcome to Dixon Tool & Manufacturing
            </Heading>
            <Text fontSize="xl" mb={6}>
              a Division of Kro Industries LLC
            </Text>
          </Box>

          <Button
            as="a"
            href="mailto:RVK@dixontoolmfg.com"
            variant="solid"
            ml={4}
            bg="#de182f"
            color="white"
            _hover={{ bg: "#bf1629", transition: "background-color 0.2s" }}
            transition="all 0.3s"
          >
            Talk to Us!
          </Button>

      </Box>

      
      


      <ProductGallery></ProductGallery>

      
      
      <Box>
        <Text m={6}>
            Dixon Tool & Manufacturing, Inc. has been producing quality parts for twenty five years. The company has gone through numerous expansions in response to customer requirements.

  Since moving into the improved facility in December of 2012, we have added more than 15 new machines. Our attention to customer service and maintaining high manufacturing standards has allowed us to expand into the 17,000 square foot structure in a relatively short time.

  Our goal is 100% customer satisfaction with long-term service and quality that will exceed expectations. Our team is vastly diversified with extensive experience and knowledge of precision components which include parts for

  Aerospace, Military, Automotive, Medical, Recreation, Electronics, Business Equipment, and Alternative Energy, along with assembly and inspection fixtures for high-end production lines. We have an array of precision machining equipment to help us handle any job from high accuracy low- volume prototypes up to high-volume production. Our customer base extends across the USA; with some of our components in the Far East.

  Onsite Engineering is available for custom designs and supports the needs of our customers.

  Dixon Tool & Manufacturing is a proud and active member of the RTMA,  and has been supporting the Manufacturing Industry  for 10 years.
        </Text>
      </Box>

      <Box textAlign="center" m="8">
        <Button as={RouterLink} to="/services">
            Our Services
        </Button>
      </Box>
    </Box>
  );
};

export default Home;
