import React from 'react';
import { Box, Heading, Text, SimpleGrid, Icon, Image } from '@chakra-ui/react';
import { HamburgerIcon, PhoneIcon } from '@chakra-ui/icons';

const Services = () => {
  return (
    <Box p={8}>
      <Heading as="h2" size="xl" mb={4}>
        Our Services
      </Heading>
      <Text fontSize="lg" mb={8}>
        At Dixon Tool & Manufacturing, we offer a range of services to meet your CNC machining and manufacturing needs.
      </Text>

      <SimpleGrid columns={[1, 2, 2, 4]} spacing={8}>
        <Box p={6} borderWidth={1} borderRadius="md" boxShadow="md">
          <Image src="/milling.png" alt="Service 1" mb={4} maxW="80px"></Image>
          <Heading as="h3" color="red.500" size="md" mb={2}>  
            CNC Machining
          </Heading>
          <Text>
            Precision CNC machining for complex parts and components to meet your exact specifications.
          </Text>
        </Box>

        <Box p={6} borderWidth={1} borderRadius="md" boxShadow="md">
          <Image src="/turning.png" alt="Service 1" mb={4} maxW="80px"></Image>
          <Heading as="h3" color="red.500" size="md" mb={2}>
            CNC Turning
          </Heading>
          <Text>
            Comprehensive inspection and quality control processes to ensure your parts meet the highest standards.
          </Text>
        </Box>

        <Box p={6} borderWidth={1} borderRadius="md" boxShadow="md">
          <Image src="/edm.png" alt="Service 1" mb={4} maxW="80px"></Image>
          <Heading as="h3" color="red.500" size="md" mb={2}>
            Wire & Sinker EDM
          </Heading>
          <Text>
            Fast turnaround on prototype parts to accelerate your product development process.
          </Text>
        </Box>

        <Box p={6} borderWidth={1} borderRadius="md" boxShadow="md">
          <Image src="/tools.png" alt="Service 1" mb={4} maxW="80px"></Image>
          <Heading as="h3" color="red.500" size="md" mb={2}>
            Tool Room Services
          </Heading>
          <Text>
            Outsource your manufacturing needs to us and benefit from our expertise and equipment.
          </Text>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default Services;
