import React from 'react';
import { Box, Heading, Text, VStack } from '@chakra-ui/react';

const Contact = () => {
  return (
    <Box p={8}>
      <Heading as="h2" size="xl" mb={6}>
        Contact Us
      </Heading>
      <VStack spacing={8} align="stretch">
        <Box>
          <Heading as="h3" size="lg" mb={4}>
            Our Address
          </Heading>
          <Text>Address: 240 Burrows St., Rochester, NY 14606-2637</Text>
          <Text>Phone: 585-235-1352</Text>
          <Text>FAX: 585-235-8465</Text>
          <Text>Or send an email to RVK@dixontoolmfg.com</Text>
        </Box>
        <Box>
          <Heading as="h3" size="lg" mb={4}>
            Location Map
          </Heading>
          <Box>
            <iframe
              width="100%"
              height="400"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
              src="https://www.google.com/maps/embed/v1/place?q=240+Burrows+St,+Rochester,+NY+14606&key=AIzaSyAoTcXPri-J0R3Bqzn4Mizg9x6WpkbjkWo"
              title="Google Map"
            ></iframe>
          </Box>
        </Box>
      </VStack>
    </Box>
  );
};

export default Contact;
