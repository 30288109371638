import React from 'react';
import { Box, Heading, Text, List, UnorderedList, ListItem, VStack } from '@chakra-ui/react';

const WhyUs = () => {
  return (
    <Box p={8}>
      <Heading as="h2" size="xl" mb={4}>
        CONTRACT MANUFACTURING:
      </Heading>

      <Text fontSize="lg" mb={4}>A business model in which a company partners with a contract manufacturer to produce components or final products based on the hiring company’s designs.</Text>

      <Text>Contract manufacturing allows businesses to focus on growing their operation while outsourcing critical manufacturing work to a contract manufacturer.</Text>

      <Text>A common version of working with a contract manufacturer goes like this:</Text>

      <UnorderedList>
        <ListItem>
          Parts are entirely manufactured by one firm for another, under the brand or label of the latter. Also referred to as “private label manufacturing,” this is a common example. For example, Taiwanese company Foxconn Technology Group is the world’s largest contract manufacturing company when it comes to electronics, having manufactured products such as the iPhone for Apple, the Kindle Fire for Amazon, and the XBox One for Microsoft.
        </ListItem>
      </UnorderedList>

      <Text>The “make or buy” decision can often be a difficult one, and due to the many advantages, businesses will often outsource secondary operations so the core team can channel its resource towards more mission-critical operations.</Text>

      <Heading as="h2" size="xl" mb={4}>
        KEY ADVANTAGES TO OUTSOURCING MACHINING
      </Heading>

      <Text>Passing up a contract because your machine shop lacks tolerance or capability is disheartening. In order to keep those contracts and build their businesses, some machine shop owners opt to outsource machining work. Here is a look at some of the benefits you will reap when you outsource CNC machining.</Text>
      
      <Heading as="h2" size="xl" mb={4}>
        EXPERTISE AND EQUIPMENT
      </Heading>

      <Text>When you outsource, you are not just getting help, you are tapping into the expertise of an experienced CNC machining provider. With contract manufacturing, you can essentially hire veterans who can produce your parts without a hiccup, eliminating the need to make a significant investment in new personnel on your team. This can be a great value if you have little or no experience with a certain type of machining.

Likewise, you can benefit from contract machining if you would have to invest in new machinery to complete the parts in a new order – seek a CM that has the equipment and expertise you need to complete the job.</Text>

      <Heading as="h2" size="xl" mb={4}>
        SCALING UP
      </Heading>

      <Text>Scaling up can be difficult when the timeline for the order is significantly greater than the time it would take to bring in new machinery or personnel (not to mention, CM can save you the costs, physical space and time of hiring and buying new equipment).

Outsourcing non-critical work to a CM like Hansford Parts and Products will enable you to produce more parts in the same timeline, while saving your core resources for the proprietary work that makes your company the big bucks.  It’s an efficient way to scale up in a short period of time and see more sales without significantly increasing your overhead.</Text>

      <Heading as="h2" size="xl" mb={4}>
        THIRD-PARTY QUALITY CONTROL
      </Heading>

      <Text>You already have in-house processes to oversee and ensure quality in your products. Choosing the right contract manufacturer means an extra set of eyes on your manufacturing process, doubling the quality levels for your output. Essentially, outsourcing allows for twice the brainpower, oversight, and handling that goes into quality control. So products are not only made more efficiently – they’re made better.</Text>

      <Heading as="h2" size="xl" mb={4}>
        IS CONTRACT MANUFACTURING IS RIGHT FOR YOU?
      </Heading>

      <Text>While there are numerous contract manufacturing advantages, it’s not necessarily for everyone. And it may well be it’s not the best timing for your organization.  Here are some questions to ask to help guide you towards a decision.</Text>

      <UnorderedList>
        <ListItem>
          Do you have a multi-faceted supply chain? If your supply chain is getting too complex, hiring a contract manufacturer can help you simplify. Experienced contract manufacturers greatly simplify the whole process and reduce moving parts for your company.
        </ListItem>
        <ListItem>
          Do you have a small-to-medium-sized business? While smaller to mid-range companies often have the resources to manage contracts remotely, operating in-house is more of a challenge. Acquiring and housing equipment can be rather difficult for operations of this size, so outsourcing offers distinct advantages to start-ups and medium- or smaller-sized organizations. 
        </ListItem>
        <ListItem>
          Does your company focus on a narrow range of parts? Some companies are capacity bound and unable to invest in their strengths. If you wish to divert attention to expanding in non-manufacturing areas, this may be perfect time to secure a contract manufacturer. A lack of functionality or internal bandwidth is a strong indicator that your company’s potential could benefit from outsourcing.
        </ListItem>
        <ListItem>
          Does the demand for your company’s production change? If the demand for your products is frequently in flux, you are losing efficiency to the friction of scaling up and down. Producers in this situation are able to offset considerable cost by outsourcing to a contract manufacturer more specialized in demand fluctuations.
        </ListItem>
      </UnorderedList>

      <Heading as="h2" size="xl" mb={4}>
        READY TO SAVE COSTS ON YOUR CNC MACHINING NEEDS?
      </Heading>

      <Text>Outsourcing your CNC machining needs creates a solid business case. When you only have an occasional need for CNC machined parts, you don’t want to spend the time and money needed to invest in building your own CNC production house. If now or at any time you have questions, please contact us at Hansford Parts and Products. We would gladly discuss feasibility, potential return for your company, and if our contract machining services are the right fit for your machine shop.</Text>
    </Box>
  );
};

export default WhyUs;
