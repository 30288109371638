import React, { useState, useEffect } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { Box, Flex, Link, Button, IconButton, useDisclosure, Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, VStack, Text, Image } from '@chakra-ui/react';
import { HamburgerIcon, PhoneIcon } from '@chakra-ui/icons';

const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Box
      bg="black"
      color="white"
      px={4}
      position="sticky"
      top={0}
      zIndex={1}
      transition="height 0.3s, padding 0.3s"
      height={isScrolled ? '60px' : ['100px', '80px', '100px']}
      py={isScrolled ? 2 : [4, 3, 4]}
    >
      <Flex h="100%" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Link as={RouterLink} to="/">
            <Image
              src="logo.png"
              alt="Dixon Tool & Manufacturing Logo"
              height={isScrolled ? '40px' : ['60px', '50px', '60px']}
              transition="height 0.3s"
            />
          </Link>
          <Flex alignItems="center" ml={4}>
            <PhoneIcon />
            <Text ml={2}>585-235-1352</Text>
          </Flex>
        </Box>
        <Flex alignItems="center" display={{ base: 'none', md: 'flex' }}>
          <Link
            as={RouterLink}
            to="/"
            mx={2}
            _hover={{ color: "#de182f", transition: "color 0.2s" }}
            _activeLink={{ color: "#de182f" }}
          >
            Home
          </Link>
          <Link
            as={RouterLink}
            to="/why-us"
            mx={2}
            _hover={{ color: "#de182f", transition: "color 0.2s" }}
            _activeLink={{ color: "#de182f" }}
          >
            Why Us
          </Link>
          <Link
            as={RouterLink}
            to="/services"
            mx={2}
            _hover={{ color: "#de182f", transition: "color 0.2s" }}
            _activeLink={{ color: "#de182f" }}
          >
            Services
          </Link>
          <Link
            as={RouterLink}
            to="/about"
            mx={2}
            _hover={{ color: "#de182f", transition: "color 0.2s" }}
            _activeLink={{ color: "#de182f" }}
          >
            About Us
          </Link>
          <Link
            as={RouterLink}
            to="/contact"
            mx={2}
            _hover={{ color: "#de182f", transition: "color 0.2s" }}
            _activeLink={{ color: "#de182f" }}
          >
            Contact
          </Link>
          <Button
            as="a"
            href="mailto:RVK@dixontoolmfg.com"
            variant="solid"
            ml={4}
            bg="#de182f"
            color="white"
            _hover={{ bg: "#bf1629", transition: "background-color 0.2s" }}
            height={isScrolled ? '32px' : ['48px', '36px', '48px']}
            fontSize={isScrolled ? 'sm' : ['md', 'sm', 'md']}
            transition="all 0.3s"
          >
            Get a Quote
          </Button>
        </Flex>
        <IconButton
          aria-label="Open Menu"
          icon={<HamburgerIcon />}
          display={{ base: 'flex', md: 'none' }}
          onClick={onOpen}
        />
      </Flex>

      <Drawer placement="right" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Menu</DrawerHeader>
          <DrawerBody>
            <VStack spacing={4} align="start">
              <Link as={RouterLink} to="/" onClick={onClose}>
                Home
              </Link>
              <Link as={RouterLink} to="/services" onClick={onClose}>
                Services
              </Link>
              <Link as={RouterLink} to="/about" onClick={onClose}>
                About Us
              </Link>
              <Link as={RouterLink} to="/contact" onClick={onClose}>
                Contact
              </Link>
              <Button
                as="a"
                href="mailto:RVK@dixontoolmfg.com"
                variant="solid"
                width="full"
                onClick={onClose}
                bg="#de182f"
                color="white"
                _hover={{ bg: "#bf1629", transition: "background-color 0.2s" }}
              >
                Get a Quote
              </Button>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default Header;
