import React from 'react';
import Slider from 'react-slick';
import { Box, Heading, Text, Image } from '@chakra-ui/react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Function to dynamically import all images from the gallery folder in the public directory
const importAllImages = () => {
  const context = require.context('../../public/gallery', false, /\.(png|jpe?g|svg)$/);
  return context.keys().map((key) => key.replace('./', '/gallery/'));
};

const images = importAllImages();

const ProductGallery = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <Box p={8}>
      <Heading as="h2" size="xl" mb={4}>
        Product Gallery
      </Heading>
      <Text fontSize="lg" mb={8}>
        Explore examples of our previous projects and state-of-the-art equipment to discover how we can meet your manufacturing needs.
      </Text>

      <Slider {...settings}>
        {images.map((src, index) => (
          <Box 
            key={index} 
            w="80%" 
            h="400px" 
            mx="auto" 
            bg="black" 
            overflow="hidden" 
            display="flex" 
            justifyContent="center" 
            alignItems="center"
          >
            <Image 
              src={src} 
              alt={`Product image ${index + 1}`} 
              objectFit="contain" 
              width="100%"
              height="100%"
              maxW="100%" 
              maxH="100%"
            />
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default ProductGallery;
