import React from 'react';
import { Box, Flex, Text, Image } from '@chakra-ui/react';

const Footer = () => {
  return (
    <Box bg="black" color="white" py={4}>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        maxWidth="1200px"
        mx="auto"
        px={4}
        flexDirection={['column', 'column', 'row', 'row']}
      >
        <Box textAlign={['center', 'center', 'left', 'left']} mb={[4, 4, 0, 0]}>
          <Text mb={2}>&copy; 2024 Dixon Tool and Manufacturing. All rights reserved.</Text>
          <Flex justifyContent={['center', 'center', 'flex-start', 'flex-start']} mt={2}>
            <Image src="iso.svg" alt="ISO Certification" height="40px" mr={4} />
            <Image src="itar.png" alt="ITAR Certification" height="40px" />
          </Flex>
        </Box>

        <Box textAlign={['center', 'center', 'right', 'right']}>
          <Text fontSize="lg" fontWeight="bold">Get in Touch</Text>
          <Text>Address: 240 Burrows St., Rochester, NY 14606-2637</Text>
          <Text>Phone: 585-235-1352</Text>
          <Text>FAX: 585-235-8465</Text>
          <Text>RVK@dixontoolmfg.com</Text>
        </Box>
      </Flex>
    </Box>
  );
};

export default Footer;
