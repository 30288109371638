import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

const About = () => {
  return (
    <Box p={8}>
      <Heading as="h2" size="xl" mb={6}>
        About Us
      </Heading>
      <Text>
      Bob Krochmalech and his team works with engineers, buyers, and machine shop 
owners to reduce costs through better manufacturing techniques and to meet 
compliance through his expertise in the machining and manufacturing industry. He built 
fixtures and machines as a tool and die maker at DSI Detection Systems Inc and 
Rockburl Industries. He built high quality, tight tolerance parts and machines to 
customer spec. As a tool maker, he was promoted to and served 8 years as plant 
manager at ERM Thermal Technologies. He was Director of Manufacturing for Vette 
Corp. He was the co-founder and Vice President and eventually President/CEO of a 
medium size Thermal Management Company in NY. Bob was the Vice President/COO 
and part owner at Hansford Parts and Products. In February 2024, Bob purchased 
Dixon Tool & Manufacturing, an existing Precision Machine shop in Rochester NY, 
now a division of Kro Industries LLC. He severs as President, CEO and owner. 
      </Text>
    </Box>
  );
};

export default About;
